import React from 'react'
import { Text } from '@chakra-ui/react'

// VERSION NUMBER AND DATE

export default function VersionNumber() {
    // *** Update CURRENT VERSION NUMBER after any updates ***

    return <Text>1.5.8</Text>
}

export function VersionDate() {
    // *** Update to CURRENT VERSION DATE ***

    return <Text>8-10-2024</Text>
}
